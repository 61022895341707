import { Component, OnInit, Input } from '@angular/core';
import { CurriculoService } from '../../../../../../../../providers/services/curriculo-service';

@Component({
  selector: 'app-tema2-mini',
  templateUrl: './tema2mini.component.html',
  styleUrls: ['./tema2mini.component.css']
})
export class Tema2MiniComponent implements OnInit
{
  public constructor(public curService:CurriculoService){}
  public ngOnInit()
  {
  }
}