import { Component } from '@angular/core';
import * as firebase from 'firebase/app';
import { FirebaseConfig } from '../environments/firebase.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'curriculo';

  public constructor()
  {
    if(firebase.apps.length == 0)
    {
      firebase.initializeApp(FirebaseConfig);
    }
  }
}