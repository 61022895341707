import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../providers/services/admin-service';
import { MenuService } from '../../../providers/services/menu-service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit
{
  public primeiroIndexMenu:number = null;
  public constructor(private _router:Router, public admService:AdminService, public menuService:MenuService)
  {
  }
  public ngOnInit()
  {
    let self = this;
    firebase.auth().onAuthStateChanged(function(usuario)
    {
      if(usuario)
      {
        self.admService.idUsuario = usuario.uid;

        usuario.providerData.forEach(function(profile)
        {
          self.admService.email = profile.email;
        });

        if(usuario.displayName != null && usuario.displayName != undefined && usuario.displayName != '')
        {
          self.admService.nome = usuario.displayName;
        }

        self.admService.getPermissoes(function(data)
        {
          if(data.val() == null)
          {
            self.logout();
          }
          else
          {
            let linha;
            data.forEach(element => {
              linha = element.val();
              if(linha.acoes.length > 0)
              {
                self.admService.setAcoes(linha.acoes);
                self.primeiroIndexMenu = self.menuService.getPrimeiroIndexMenuAdmin();
                if(self.primeiroIndexMenu != null)
                {
                  self.menuService.selecionado = self.primeiroIndexMenu;

                  let index = self.menuService.selecionado;
                  let opcoes = self.menuService.opcoesAdmin;
                  let caminho = opcoes[index].caminho;
                  let partes = caminho.split('/');
                  self._router.navigate(['admin/main/'+partes[partes.length - 1]]);
                }
              }
              else
              {
                self._router.navigate(["admin"]);
              }
            });
          }
        });
      }
      else
      {
        self._router.navigate(["admin"]);
      }
    });
  }

  public give()
  {
    let self = this;
    let data = {
      usuario_id:self.admService.idUsuario,
      permissoes:{
        admin:true,
        revisao:true,
        ingles:true,
        espanhol:true
      }
    };
    self.admService.setPermissoes(data, function(ret){
      console.log(ret);
    });
  }
  public logout()
  {
    let self = this;
    self.admService.idUsuario = null;
    firebase.auth().signOut()
      .then(ok => {
        self._router.navigate(["admin"]);
      })
      .catch(err => {
        console.error(err);
      });
  }
}