import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { MenuService } from './menu-service';

@Injectable()
export class AdminService
{
    public idUsuario:string;
    public nome:string;
    public email:string;
    public acoes:Array<string> = [];
    public constructor(private _database:AngularFireDatabase, public menuService:MenuService){}
    public getPermissoes(callback)
    {
        let self = this;
        self._database.list('permissoes').query
            .orderByChild('usuario_id')
            .equalTo(self.idUsuario)
            .once('value', snapshot => {
                callback(snapshot);
            });
    }
    public setPermissoes(data, callback)
    {
        let self = this;
        self._database.list('permissoes').push(data)
        .then(
            t => {
                callback(t.key);
            },
            e => { console.log(e.message); });
    }
    public setAcoes(lista)
    {
        let self = this;
        self.acoes = [];
        let hasRoot:boolean = false;
        for(let i = 0; i < lista.length; i++)
        {
            self.acoes.push(lista[i]);
            if(lista[i] === 'root')
            {
                hasRoot = true;
            }
        }
        
        if(hasRoot){ self.menuService.mostrarTodosOsMenusAdmin(); }
    }
    public addPermissoes(data, callback)
    {
        let self = this;
        self._database.list('permissoes').push(data)
            .then(
                t => {
                    callback(t.key);
                },
                e => { console.log(e.message); });
    }
}