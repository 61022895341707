import { Component, OnInit } from '@angular/core';
import { CurriculoService } from '../../../../../providers/services/curriculo-service';
import { PDFService } from '../../../../../providers/services/pdf-service';
import { Utils } from '../../../../../providers/utils';
import { config } from '../../../../../providers/config';
import { LogService } from '../../../../../providers/services/log-service';
import { PagamentoService } from '../../../../../providers/services/pagamento-service';

declare var jQuery;

@Component({
  selector:'app-menu',
  templateUrl:'./menu.component.html',
  styleUrls:['./menu.component.css']
})
export class MenuComponent implements OnInit
{
  public qtdsLog:any = {
    acessos:0,
    downloads:0
  };
  public linkAcesso:string;
  public url:string;
  public constructor(
    public curService:CurriculoService,
    public pdfService:PDFService,
    public utils:Utils,
    public logService:LogService,
    public pagService:PagamentoService){
    this.url = config.url+'#/';
  }
  public ngOnInit()
  {
    this.carregarQtdsAcessos();
  }
  public detalhesCompra()
  {
    let self = this;
    self.pagService.loadByCV(self.curService.id);
    jQuery('#modalCompras').modal();
  }
  public carregarQtdsAcessos()
  {
    let self = this;
    if(self.curService.id == null || self.curService.id == undefined)
    {
      setTimeout(function(){ self.carregarQtdsAcessos() }, 1000);
    }
    else
    {
      self.logService.getQtds(self.curService.id, function(data)
      {
        self.qtdsLog = data;
      });
    }
  }
  public novaSecao()
  {
    let self = this;
    jQuery('#modalNovaSecao').modal();
    self.curService.marcarPrimeiraOpcaoDisponivel();
    self.curService.coluna = null;
  }
  public switchPublicoPrivado()
  {
    let self = this;
    self.curService.data.publico = !self.curService.data.publico;
    self.curService.salvar();
  }
  public iniciarTrocaCor()
  {
    jQuery('#modalTrocarCorDestaque').modal();
  }
  public iniciarTrocaTema()
  {
    jQuery('#modalTrocarTema').modal();
  }
  public iniciarUpgrade()
  {
    jQuery('#modalUpgrade').modal();
  }
  public copiarLink()
  {
    let self = this;
    let ret = self.utils.copyToClipboard(document.getElementById('linkCV'));
    if(ret)
    {
      self.utils.toast("Link copiado para Área de Transferência");
    }
    else
    {
      self.utils.toast("Erro ao copiar Link");
    }
  }
  public remover()
  {
    jQuery('#modalRemover').modal();
  }

  private _colunaInicial:string = null;
  private _colunaFinal:string = null;
  private _indiceInicial:number = null;
  private _indiceFinal:number = null;
  private _secaoTemp = null;
  public iniciarReorganizarSecoes()
  {
    let self = this;
    let trocou:boolean = false;
    let temp:string;
    jQuery('#modalReorganizar').on('shown.bs.modal', function(e)
    {
      jQuery(".col-x").sortable({
        revert:true,
        connectWith:'.col-x',
        placeholder:'secao-placeholder',
        forcePlaceholderSize:true,
        start:function(event, ui)
        {
          trocou = false;
          self._colunaInicial = /col\-B/gi.test(jQuery(this).closest('.col-x').attr('class')) ? 'B' : 'A';
          self._indiceInicial = ui.item.index();
        },
        receive:function(event, ui)
        {
          trocou = true;
          self._colunaInicial = /col\-B/gi.test(jQuery(this).closest('.col-x').attr('class')) ? 'B' : 'A';
        },
        stop:function(event, ui)
        {
          self._colunaFinal = /col\-B/gi.test(jQuery(this).closest('.col-x').attr('class')) ? 'B' : 'A';
          self._indiceFinal = ui.item.index();

          if(trocou)
          {
            temp = self._colunaInicial;
            self._colunaInicial = self._colunaFinal;
            self._colunaFinal = temp;
          }
          
          //console.log(self._colunaInicial+":"+self._indiceInicial+" - "+self._colunaFinal+":"+self._indiceFinal);          
          self.ajustarColunas();
        }
      });
      jQuery(".col-x").disableSelection();
    });

    jQuery('#modalReorganizar').modal();
  }

  public ajustarColunas()
  {
    let self = this;

    let arrColunaInicial:Array<any> = [];
    let arrColunaFinal:Array<any> = [];

    arrColunaInicial = self._colunaInicial == 'B' ? self.curService.data.colunaB : self.curService.data.colunaA;
    arrColunaFinal = self._colunaFinal == 'B' ? self.curService.data.colunaB : self.curService.data.colunaA;

    //console.log(arrColunaInicial);
    //console.log(arrColunaFinal);

    self._secaoTemp = arrColunaInicial[self._indiceInicial];

    //REMOVO DA POSIÇÃO INICIAL
    arrColunaInicial.splice(self._indiceInicial, 1);

    //ADICIONO NA POSIÇÃO INICIAL
    arrColunaFinal.splice(self._indiceFinal, 0, self._secaoTemp);
    //console.log(self._colunaInicial+":"+self._indiceInicial+" - "+self._colunaFinal+":"+self._indiceFinal);
    self.curService.salvar(function()
    {
      self._secaoTemp = null;
      self._colunaInicial = null;
      self._colunaFinal = null;
      self._indiceInicial = null;
      self._indiceFinal = null;
      arrColunaInicial = [];
      arrColunaFinal = [];

      setTimeout(function()
      {
        self.curService.verificacaoInicialEdicao(self.curService.id);
      }, 500);
    });

  }
}