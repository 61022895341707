import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AdminComponent } from './admin.component';
import { MainComponent } from './main/main.component';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { RevisoesComponent } from './main/revisoes/revisoes.component';

//import { CurriculoModule } from './curriculo/curriculo.module';
import { AppRoutingModule } from '../app-routing.module';
@NgModule({
  imports: [CommonModule,FormsModule,AppRoutingModule],
  exports:[AdminComponent,MainComponent,DashboardComponent,RevisoesComponent],
  declarations:[AdminComponent,MainComponent,DashboardComponent,RevisoesComponent]
})
export class AdminModule { }
