import { Injectable } from '@angular/core';
import { CurriculoService } from './curriculo-service';
import { PagamentoService } from './pagamento-service';
import * as firebase from 'firebase';

declare var jQuery;

@Injectable()
export class ServicoService
{
    public id:string;

    public total:number = 0;
    public todosSelecionados:boolean = false;
    public descontoTodos:number = 25;

    public status:Array<any> = [
        {indice:1, nome:'Aguardando pagamento', descricao:'Comprador iniciou a transação, mas até o momento o PagSeguro não recebeu nenhuma informação sobre o pagamento.'},
        {indice:2, nome:'Em análise', descricao:'Comprador optou por pagar com um cartão de crédito e o PagSeguro está analisando o risco da transação.'},
        {indice:3, nome:'Paga', descricao:'Transação foi paga pelo comprador e o PagSeguro já recebeu uma confirmação da instituição financeira responsável pelo processamento.'},
        {indice:4, nome:'Disponível', descricao:'Transação foi paga e chegou ao final de seu prazo de liberação sem ter sido retornada e sem que haja nenhuma disputa aberta.'},
        {indice:5, nome:'Em disputa', descricao:'Comprador, dentro do prazo de liberação da transação, abriu uma disputa'},
        {indice:6, nome:'Devolvida', descricao:'Valor da transação foi devolvido para o comprador.'},
        {indice:7, nome:'Cancelada', descricao:'Transação foi cancelada sem ter sido finalizada.'},
    ];

    public data = {
        dados:null,
        curriculo_id:null,
        data:firebase.database.ServerValue.TIMESTAMP,
        data_aprovacao:firebase.database.ServerValue.TIMESTAMP,
        data_limite_download:firebase.database.ServerValue.TIMESTAMP,
        status:null,
        usuario_id:null,
        desconto:0,
        valor_total:null,
        valor_a_pagar:null,
        pago:false,
        servicos:[],
    };
    public constructor(public curService:CurriculoService, private _pagService:PagamentoService)
    {
        this.calcularTotal();
    }

    public opcoes:Array<any> = [
        {
            titulo:'Remover rodapé',
            idPopOver:"popOverGeracaoPDF",
            observacoes:[
                "Após a contratação, a URL do CurriculoAPP e a Logomarca ficarão ocultos por <u><i><b>30 dias corridos</b></i></u>.",
                "Durante este período, não será possível alterar seu nome no currículo. Verifique se ele esta correto antes de efetuar o Upgrade."
            ],
            paragrafos:[
                "A URL do CurrículoAPP e a Logomarca não serão mais exibidos no rodapé.",
                "Isso vale tanto para exibição no navegador quanto para a versão PDF do currículo."
            ],
            servicos:[
                {
                    indice:1,
                    rotulo:"Remover URL e Logomarca do rodapé",
                    valor:14.80,
                    selecionado:false,
                    dependencia:null,
                    habilitado:true
                }
            ]
        },
        {
            titulo:'Revisão de currículo',
            idPopOver:"popOverRevisao",
            observacoes:[
                "A revisão será feita em até <u><i><b>7 dias úteis</b></i></u>. Caso precise que fique pronto antes, marque a opção <u><i><b>Solicitar urgência</b></i></u>. Neste caso, a avaliação será feita em no máximo <u><i><b>2 dias úteis</b></i></u>."
            ],
            paragrafos:[
                "Nossos especialistas analisam seu currículo, efetuam correções e dão as dicas que podem ser o que falta pra sua contratação.",
                "Será criada uma cópia do seu currículo com comentários em cada seção que nossos analistas julgarem pertinente."
            ],
            servicos:[
                {
                    indice:3,
                    rotulo:"Contratar revisão",
                    valor:59.90,
                    selecionado:false,
                    dependencia:null,
                    habilitado:true
                },
                {
                    indice:4,
                    rotulo:"Solicitar urgência",
                    valor:29.90,
                    selecionado:false,
                    dependencia:0,
                    habilitado:false
                }
            ]
        },
        {
            titulo:'Traduzir',
            idPopOver:"popOverTraduzir",
            observacoes:[
                "As traduções serão feitas em até <u><i><b>7 dias úteis</b></i></u>. Caso precise que fique pronto antes, marque a opção <u><i><b>Solicitar urgência</b></i></u> em cada idioma desejado. Neste caso, a tradução será feita em no máximo <u><i><b>2 dias úteis</b></i></u>."
            ],
            paragrafos:[
                "Nossos tradutores nativos farão seu currículo ser ainda mais atrativos para empresas do exterior e multinacionais.",
                "Será criada uma cópia do seu currículo para cada idioma selecionado."
            ],
            servicos:[
                {
                    indice:5,
                    rotulo:"Traduzir para Inglês",
                    valor:119.90,
                    selecionado:false,
                    dependencia:null,
                    habilitado:true
                },
                {
                    indice:6,
                    rotulo:"Solicitar urgência para Inglês",
                    valor:59.90,
                    selecionado:false,
                    dependencia:0,
                    habilitado:false
                },
                {
                    indice:7,
                    rotulo:"Traduzir para Espanhol",
                    valor:119.90,
                    selecionado:false,
                    dependencia:null,
                    habilitado:true
                },
                {
                    indice:8,
                    rotulo:"Solicitar urgência para Espanhol",
                    valor:59.90,
                    selecionado:false,
                    dependencia:2,
                    habilitado:false
                }
            ]
        },
    ];

    public selecionar(coluna, servico)
    {
        let self = this;

        //SELECIONO
        servico.selecionado = !servico.selecionado;

        //MARCO AS DEPENDENCIAS
        for(let i = 0; i < coluna.servicos.length; i++)
        {

            if(coluna.servicos[i].dependencia != null)
            {
                if(servico == coluna.servicos[coluna.servicos[i].dependencia])
                {
                    coluna.servicos[i].selecionado = false;
                    coluna.servicos[i].habilitado = servico.selecionado;
                }
            }
        }
        self.calcularTotal();
    }
    public calcularTotal()
    {
        let self = this;
        self.total = 0;
        let marcouTodos = true;
        for(let i = 0; i < self.opcoes.length; i++)
        {
            for(let j = 0; j < self.opcoes[i].servicos.length; j++)
            {
                if(self.opcoes[i].servicos[j].selecionado && self.opcoes[i].servicos[j].habilitado)
                {
                    self.total+= self.opcoes[i].servicos[j].valor;
                }
                else
                {
                    marcouTodos = false;
                }
            }
        }
        self.todosSelecionados = marcouTodos;
    }
    public selecionarTodos()
    {
        let self = this;
        self.todosSelecionados = !self.todosSelecionados;
        for(let i = 0; i < self.opcoes.length; i++)
        {
            for(let j = 0; j < self.opcoes[i].servicos.length; j++)
            {
                self.opcoes[i].servicos[j].selecionado = self.todosSelecionados;
                if(self.todosSelecionados)
                {
                    self.opcoes[i].servicos[j].habilitado = true;
                }
                else if(self.opcoes[i].servicos[j].dependencia !== null)
                {
                    self.opcoes[i].servicos[j].habilitado = false;
                }
            }
        }
        self.calcularTotal();
    }
    public servicosContratados:Array<any> = [];
    public confirmarContratacao()
    {
        let self = this;
        self.servicosContratados = [];

        //GERAR PDF
        if(self.opcoes[0].servicos[0].selecionado)
        {
            //REMOVER URL e LOGO
            self.servicosContratados.push({
                servico:"Remover URL e Logomarca do rodapé",
                observacao:"Durante os 30 dias corridos seu currículo não exibirá a URL do CurriculoAPP nem a Logomarca no rodapé. No entanto, seu nome completo estará bloqueado para edição neste período. Confira se ele esta correto: <span style='font-family:ubuntu;font-weight:bold;'>"+self.curService.data.pessoal.nome+"</span>",
                prazo:null
            });
        }

        //REVISAO
        if(self.opcoes[1].servicos[0].selecionado)
        {
            self.servicosContratados.push({
                servico:"Revisão do Currículo",
                observacao:"Qualquer alteração no currículo após efetuar o pagamento não será considerada por nossos revisores",
                prazo:7
            });

            //PRIORIDADE
            if(self.opcoes[1].servicos[1].selecionado)
            {
                self.servicosContratados[self.servicosContratados.length - 1].servico+= " - com urgência"
                self.servicosContratados[self.servicosContratados.length - 1].prazo = 2;
            }
        }

        //TRADUCAO INGLÊS
        if(self.opcoes[2].servicos[0].selecionado)
        {
            self.servicosContratados.push({
                servico:"Traduzir para Inglês",
                observacao:"Qualquer alteração no currículo após efetuar o pagamento não será considerada por nossos tradutores",
                prazo:7
            });

            //PRIORIDADE
            if(self.opcoes[2].servicos[2].selecionado)
            {
                self.servicosContratados[self.servicosContratados.length - 1].servico+= " - com urgência"
                self.servicosContratados[self.servicosContratados.length - 1].prazo = 2;
            }
        }

        //TRADUCAO ESPANHOL
        if(self.opcoes[2].servicos[2].selecionado)
        {
            self.servicosContratados.push({
                servico:"Traduzir para Espanhol",
                observacao:"Qualquer alteração no currículo após efetuar o pagamento não será considerada por nossos tradutores",
                prazo:7
            });

            //PRIORIDADE
            if(self.opcoes[2].servicos[3].selecionado)
            {
                self.servicosContratados[self.servicosContratados.length - 1].servico+= " - com urgência"
                self.servicosContratados[self.servicosContratados.length - 1].prazo = 2;
            }
        }

        //console.log(self.servicosContratados);

        //CARREGO A MODAL
        jQuery('#modalConfirmarUpgrade').modal();
    }

    public contratar()
    {
        let self = this;
        self.data.dados = self.curService.data;
        self.data.curriculo_id = self.curService.id;
        self.data.status = self.status[0].indice;
        self.data.valor_total = self.total;

        //VALOR COM DESCONTO (SE HOUVER)
        if(!self.todosSelecionados)
        {
            self.data.desconto = 0;
            self.data.valor_a_pagar = self.total;
        }
        else
        {
            self.data.desconto = self.descontoTodos;
            self.data.valor_a_pagar = self.total - (self.total * self.descontoTodos / 100);
        }

        //SERVICOS
        self.data.servicos = [];
        for(let i = 0; i < self.opcoes.length; i++)
        {
            for(let j = 0; j < self.opcoes[i].servicos.length; j++)
            {
                if(self.opcoes[i].servicos[j].selecionado && self.opcoes[i].servicos[j].habilitado)
                {
                    self.data.servicos.push(self.opcoes[i].servicos[j]);
                }
            }
        }

        self._pagService.efetuar(self.data);
        
    }
}