import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { InsideComponent } from './inside.component';
import { PrecosComponent } from './precos/precos.component';
import { SobreComponent } from './sobre/sobre.component';
import { ContatoComponent } from './contato/contato.component';

import { CurriculoModule } from './curriculo/curriculo.module';
import { AppRoutingModule } from '../app-routing.module';
@NgModule({
  imports:[CommonModule,FormsModule,CurriculoModule,AppRoutingModule],
  exports:[InsideComponent, PrecosComponent, SobreComponent, ContatoComponent],
  declarations:[InsideComponent, PrecosComponent, SobreComponent, ContatoComponent]
})
export class InsideModule{}