import {Injectable} from '@angular/core';

@Injectable()
export class Utils
{
    public inArray(array, valor)
    {
        return array.indexOf(valor) >= 0;
    }
    public isEmail(email)
    {
        let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(email);
    }

    public getDataByTs(data)
    {
        let obj = new Date(data);
        let ano = obj.getFullYear();
        let mes = (obj.getMonth() + 1).toString();
        let dia = obj.getDate().toString();
        let hora = obj.getHours().toString();
        let minuto = obj.getMinutes().toString();

        dia = parseInt(dia) < 10 ? '0'+dia : dia.toString();
        mes = parseInt(mes) < 10 ? '0'+mes : mes.toString();
        hora = parseInt(hora) < 10 ? '0'+hora : hora.toString();
        minuto = parseInt(minuto) < 10 ? '0'+minuto : minuto.toString();

        return dia+'/'+mes+'/'+ano+' '+hora+':'+minuto;
    }

    public copyToClipboard(elem)
    {
        let target;
        let currentFocus;
        // create hidden text element, if it doesn't already exist
        
        let targetId = "_hiddenCopyText_";
        let isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
        let origSelectionStart, origSelectionEnd;
        if(isInput)
        {
            // can just use the original source element for the selection and copy
            target = elem;
            origSelectionStart = elem.selectionStart;
            origSelectionEnd = elem.selectionEnd;
        }
        else
        {
            // must use a temporary form element for the selection and copy
            target = document.getElementById(targetId);
            if(!target)
            {
                target = document.createElement("textarea");
                target.style.position = "absolute";
                target.style.left = "-9999px";
                target.style.top = "0";
                target.id = targetId;
                document.body.appendChild(target);
            }
            target.textContent = elem.textContent;
        }
        // select the content
        currentFocus = document.activeElement;
        target.focus();
        target.setSelectionRange(0, target.value.length);

        // copy the selection
        let succeed;
        try
        {
            succeed = document.execCommand("copy");
        }
        catch(e)
        {
            succeed = false;
        }
        // restore original focus
        if(currentFocus && typeof currentFocus.focus === "function")
        {
            currentFocus.focus();
        }
      
        if(isInput)
        {
            // restore prior selection
            elem.setSelectionRange(origSelectionStart, origSelectionEnd);
        }
        else
        {
            // clear temporary content
            target.textContent = "";
        }
        return succeed;
    }
    public toast(mensagem)
    {
        let x = document.getElementById("snackbar");
        x.innerHTML = mensagem;
        x.className = "show";
        setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    }
    public getNavegador()
    {
        const agent = window.navigator.userAgent.toLowerCase()
        switch(true)
        {
            case agent.indexOf('edge') > -1:
                return 'Edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'Opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'Chrome';
            case agent.indexOf('trident') > -1:
                return 'Internet Explorer';
            case agent.indexOf('firefox') > -1:
                return 'Firefox';
            case agent.indexOf('safari') > -1:
                return 'Safari';
            default:
                return 'Outro';
        }
    }
    public getSO()
    {
        let ret = "Outro"; 
        if(navigator.appVersion.indexOf("Win") != -1){ ret = "Windows"; }
        else if(navigator.appVersion.indexOf("Mac") != -1){ ret = "MacOS"; }
        else if(navigator.appVersion.indexOf("X11") != -1){ ret = "Unix"; }
        else if(navigator.appVersion.indexOf("Linux") != -1){ ret = "Linux"; }

        return ret;
    }
}