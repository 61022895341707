import { Injectable } from '@angular/core';

@Injectable()
export class MenuService
{
    public selecionado:number;
    public opcoes:Array<any> = [
        {
            indice:1,
            texto:'Meus currículos',
            caminho:'/#/inside/curriculo'
        },
        {
            indice:2,
            texto:'Serviços',
            caminho:'/#/inside/precos'
        },
        /*
        {
            indice:3,
            texto:'Sobre',
            caminho:'/#/inside/sobre'
        },
        */
        {
            indice:3,
            texto:'Fale conosco',
            caminho:'/#/inside/contato'
        }
    ];
    public opcoesAdmin:Array<any> = [
        {
            indice:0,
            texto:'Dashboard',
            caminho:'/#/admin/main/dashboard',
            visivel:false
        },
        /*
        {
            indice:1,
            texto:'Revisões',
            caminho:'/#/admin/main/revisoes',
            visivel:false
        },
        {
            indice:2,
            texto:'Traduções em inglês',
            caminho:'/#/admin/main/traducoes_ingles',
            visivel:false
        },
        {
            indice:3,
            texto:'Traduções em espanhol',
            caminho:'/#/admin/main/traducoes_espanhol',
            visivel:false
        },
        {
            indice:4,
            texto:'Admin',
            caminho:'/#/admin/main/admin',
            visivel:false
        },
        */
    ];
    public constructor(){}
    public mostrarTodosOsMenusAdmin()
    {
        let self = this;
        for(let i = 0; i < self.opcoesAdmin.length; i++)
        {
            self.opcoesAdmin[i].visivel = true;
        }
    }
    public getPrimeiroIndexMenuAdmin()
    {
        let self = this;
        let primeiroIndice:number = null;
        for(let i = 0; i < self.opcoesAdmin.length; i++)
        {
            if(self.opcoesAdmin[i].visivel)
            {
                primeiroIndice = self.opcoesAdmin[i].indice;
                break;
            }
        }
        return primeiroIndice;
    }
}

