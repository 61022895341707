import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurriculoService } from '../../providers/services/curriculo-service';
import { ServicoService } from '../../providers/services/servico-service';
import { MenuService } from '../../providers/services/menu-service';
import * as firebase from 'firebase';

declare var jQuery;

@Component({
    selector: 'app-inside',
    templateUrl: './inside.component.html',
    styleUrls: ['./inside.component.css']
})
export class InsideComponent implements OnInit
{
    public nomeUsuarioLogado:string = null;
    public emailUsuarioLogado:string = null;
    public constructor(private _router:Router, public curService:CurriculoService, public serService:ServicoService, public menuService:MenuService)
    {
    }
    public ngOnInit()
    {
        let self = this;
        
        jQuery('#modalCarregando').modal();

        firebase.auth().onAuthStateChanged(function(usuario)
        {
          if(usuario)
          {
              //SERVICO
              self.serService.data.usuario_id = usuario.uid;
              self.curService.idUsuarioLogado = usuario.uid;

              usuario.providerData.forEach(function(profile)
              {
                self.emailUsuarioLogado = profile.email;
              });

              if(usuario.displayName != null && usuario.displayName != undefined && usuario.displayName != '')
              {
                self.nomeUsuarioLogado = 'Olá '+usuario.displayName.split(" ")[0];
              }
              else
              {
                self.nomeUsuarioLogado = self.emailUsuarioLogado;
              }
          }
          else
          {
            self._router.navigate([""]);
            jQuery('#modalCarregando').modal('hide');
          }
        });
    }
    public logout()
    {
      let self = this;
      firebase.auth().signOut()
        .then(ok => {
          self.curService.limparDados();
          self._router.navigate([""]);
        })
        .catch(err => {
          console.error(err);
        });
    }
}