import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CurriculoComponent } from './curriculo.component';
import { EdicaoComponent } from './edicao/edicao.component';
import { MenuComponent } from './edicao/menu/menu.component';

import { PreenchimentoModule } from './edicao/preenchimento/preenchimento.module';

@NgModule({
  imports: [CommonModule,FormsModule,PreenchimentoModule],
  exports:[CurriculoComponent,EdicaoComponent,MenuComponent],
  declarations:[CurriculoComponent,EdicaoComponent,MenuComponent]
})
export class CurriculoModule { }
