import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../utils';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';

@Injectable()
export class LogService
{
    public info:any = {
        data:null,
        curriculo_id:null,
        ip:null,
        navegador:null,
        so:null
    };
    public constructor(private _http:HttpClient, private _utils:Utils, private _database:AngularFireDatabase){}

    public getQtds(idCurriculo, callback)
    {
        let self = this;
        let ret = {
            acessos:0,
            downloads:0
        };

        //ACESSOS
        self._database.list('log_acessos').query
            .orderByChild('curriculo_id')
            .equalTo(idCurriculo)
            .on('value', snapshot => {
                ret.acessos = snapshot.numChildren();

                if(ret.acessos > 0)
                {
                    //DOWNLOADS
                    self._database.list('log_downloads').query
                    .orderByChild('curriculo_id')
                    .equalTo(idCurriculo)
                    .on('value', snapshot => {
                        ret.downloads = snapshot.numChildren();
                        callback(ret);
                    });
                }
                else
                {
                    callback(ret);
                }
            });
    }
    public gravar(idCurriculo, tipo)
    {
        let self = this;
        let colecao:string = tipo == 'download' ? 'log_downloads' : 'log_acessos';
        self._http.get('https://api.ipify.org/?format=json').toPromise()
            .then(data => {

                //CARREGO O ARRAY
                self.info.curriculo_id = idCurriculo;
                self.info.data = firebase.database.ServerValue.TIMESTAMP;
                self.info.ip = data['ip'] == undefined ? null : data['ip'];
                self.info.navegador = self._utils.getNavegador();
                self.info.so = self._utils.getSO();

                //GRAVO NO DB
                self._database
                    .list(colecao)
                    .push(self.info)
                    .then(t => {
                        //console.log('Ok');
                    },
                    e => { console.log(e.message); });
            })
            .catch(err => { console.log(err); });
    }
}