import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from '../../../providers/utils';

import * as firebase from 'firebase/app';

declare var jQuery;

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit
{
  public botaoLoginTexto:string = "Entrar";
  public botaoLoginDisabled:boolean = false;

  public msgErro:string = null;
  public email:string;
  public senha:string;
  public constructor(private _router:Router, private _utils:Utils)
  {
  }
  public ngOnInit()
  {
    let self = this;

    firebase.auth().onAuthStateChanged(function(usuario)
    {
      if(usuario)
      {
        self._router.navigate(["inside/curriculo"]);
      }
      else
      {
        jQuery("input").on('keydown', function(e)
        {
          if(e.which == 13)
          {
            e.preventDefault();
            self.login();
          }
        });
      }
    });
  }
  public login()
  {
    let self = this;
    if(self.email == null || self.email == undefined || self.email == "")
    {
      self.msgErro = 'Digite o e-mail';
    }
    else if(!self._utils.isEmail(self.email))
    {
      self.msgErro = 'E-mail inválido';
    }
    else if(self.senha == null || self.senha == undefined || self.senha == "")
    {
      self.msgErro = 'Digite a senha';
    }
    else
    {
      self.finalizarLogin();
    }
  }
  public finalizarLogin()
  {
    let self = this;

    self.botaoLoginTexto = "<i class='fa fa-spinner fa-pulse fa-fw'></i> &nbsp; Entrando...";
    self.botaoLoginDisabled = true;
  
    firebase.auth()
      .signInWithEmailAndPassword(self.email, self.senha)
      .then(ok => {
        self.botaoLoginTexto = "Entrar";
        self.botaoLoginDisabled = false;
    
        self._router.navigate(["/inside/curriculo"]);
        self.email = null;
        self.senha = null;
      })
      .catch(err => {
        self.botaoLoginTexto = "Entrar";
        self.botaoLoginDisabled = false;
        self.msgErro = 'E-mail ou senha inválido';
        self.senha = null;
        console.error(err.message);
      });
  }

}
