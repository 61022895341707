import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../../../providers/services/menu-service';
import { CurriculoService } from '../../../providers/services/curriculo-service';
import { config } from '../../../providers/config';
import * as firebase from 'firebase';

declare var jQuery;

@Component({
    selector: 'app-curriculo',
    templateUrl: './curriculo.component.html',
    styleUrls: ['./curriculo.component.css']
})
export class CurriculoComponent implements OnInit
{
    public url:string = config.url;
    public lista:Array<any> = [];
    public constructor(private _router:Router, public menuService:MenuService, public curService:CurriculoService)
    {
        this.menuService.selecionado = 1;
    }
    public ngOnInit()
    {
        let self = this;
        firebase.auth().onAuthStateChanged(function(usuario)
        {
            if(usuario)
            {
                self.curService.getByListaUsuario(usuario.uid, function(data)
                {
                    self.lista = data;
                });
            }
            jQuery('#modalCarregando').modal('hide');
        });
    }
    public novo()
    {
        let self = this;
        self.curService.add(function(id)
        {
            self._router.navigate(["/inside/curriculo/edicao/"+id]);
        });
    }
}
  