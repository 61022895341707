import { Component, OnInit, Input } from '@angular/core';
import { CurriculoService } from '../../../../../providers/services/curriculo-service';

@Component({
  selector: 'app-view-curriculo-preenchimento-conteudo-tema1-coluna',
  templateUrl: './curriculo-preenchimento-conteudo-tema1-coluna.component.html',
  styleUrls: ['./curriculo-preenchimento-conteudo-tema1-coluna.component.css']
})
export class CurriculoPreenchimentoConteudoTema1ColunaComponent implements OnInit
{
  @Input() coluna:string;
  public idColuna:string;
  public listaColuna:Array<any> = [];
  public constructor(public curService:CurriculoService){}
  public ngOnInit()
  {
  }
  private _iniciou:boolean = false;
  public ngAfterViewChecked()
  {
    if(!this._iniciou || this.listaColuna.length == 0)
    {
      let self = this;
      self.listaColuna = self.coluna == 'B' ? self.curService.data.colunaB : self.curService.data.colunaA;
      self._iniciou = true;
    }
  }
}