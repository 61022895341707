import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../../providers/services/menu-service';
import { config } from '../../../providers/config';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../../providers/utils';

import * as firebase from 'firebase';

declare var jQuery;

@Component({
    selector: 'app-contato',
    templateUrl: './contato.component.html',
    styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit
{
    public botaoTexto:string = "Enviar";
    public botaoDisabled:boolean = false;

    public mensagem:string;
    public msgErro:string;
    public constructor(public menuService:MenuService, private _http:HttpClient, public utils:Utils)
    {
        this.menuService.selecionado = 3;
    }
    public ngOnInit()
    {
        jQuery('#modalCarregando').modal('hide');
    }
    public enviar()
    {
        let self = this;
        if(self.mensagem == null || self.mensagem == "")
        {
            self.msgErro = "Digite uma mensagem";
        }
        else
        {
            let usuario = firebase.auth().currentUser;
            if(usuario)
            {
                self.botaoTexto = "<i class='fa fa-spinner fa-pulse fa-fw'></i> &nbsp; Enviando...";
                self.botaoDisabled = true;

                let remetente = usuario.displayName == null || usuario.displayName == undefined ? usuario.email : usuario.displayName+"<"+usuario.email+">";
                let info = {
                    remetente:remetente,
                    assunto:"Contato enviado pelo CurriculoAPP",
                    mensagem:self.mensagem,
                    mensagemHTML:self.mensagem.replace(/\n/g, "<br>"),
                };
                let url = config.webservice+"curriculo/email/enviar";
                self._http.post(url, info).toPromise()
                    .then(data => {
                        console.log(data);
                        self.botaoTexto = "Enviar";
                        self.botaoDisabled = false;
                        self.utils.toast("Mensagem enviada com sucesso.");
                        self.mensagem = "";
                    })
                    .catch(err => {
                        console.log(err);
                        self.botaoTexto = "Enviar";
                        self.botaoDisabled = false;
                        self.utils.toast("Erro ao enviar mensagem. Por favor, tente novamente mais tarde.");
                    });
            }
        }
    }
}