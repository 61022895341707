import { Component, Input } from '@angular/core';
import { CurriculoService } from '../../../../../../../../providers/services/curriculo-service';

declare var jQuery;

@Component({
  selector: 'app-coluna-mini',
  templateUrl: './colunamini.component.html',
  styleUrls: ['./colunamini.component.css']
})
export class ColunaMiniComponent
{
  @Input() coluna:string;
  public idColuna:string;
  public constructor(public curService:CurriculoService)
  {
    let self = this;
  }
}