import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EsqueciSenhaComponent } from './esquecisenha.component';

import { AngularFireAuth } from 'angularfire2/auth';

@NgModule({
  imports:[CommonModule,FormsModule],
  declarations:[EsqueciSenhaComponent],
  exports:[EsqueciSenhaComponent],
  providers:[AngularFireAuth]
})
export class EsqueciSenhaModule {}