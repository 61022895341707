import { Component, OnInit, ViewChild } from '@angular/core';
import { CurriculoService } from '../../../../../../providers/services/curriculo-service';
import { FormBuilder, FormGroup } from "@angular/forms";
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from 'angularfire2/storage';
import { Observable } from 'rxjs/Observable';

import { ImageCroppedEvent } from 'ngx-image-cropper';

declare var jQuery;

@Component({
  selector: 'app-destaque',
  templateUrl: './destaque.component.html',
  styleUrls: ['./destaque.component.css']
})
export class DestaqueComponent implements OnInit
{
  @ViewChild('fotoUsuario', null) fotoUsuario;
  form: FormGroup;
  public uploadProgress:Observable<number>;
  public downloadURL:Observable<string> = null;
  public constructor(public curService:CurriculoService, private _fb:FormBuilder, private _storage:AngularFireStorage)
  {
  }
  public ngOnInit()
  {
    this.createForm();
  }
  public iniciarTrocaFoto()
  {
    this.curService.secaoSelecionada = 'destaque';
    jQuery('#fotoUsuario').click();
  }
  public createForm()
  {
    this.form = this._fb.group({
      fotoUsuario:null
    });
  }
  public showHideOpcoesConfig(event)
  {
    event.stopPropagation();
    this.curService.showConfigDestaque = !this.curService.showConfigDestaque;
    this.curService.secaoSelecionada = 'destaque';
  }

  public ref:AngularFireStorageReference;
  public task:AngularFireUploadTask;
  public changeImage(event)
  {

  }


  public imageChangedEvent:any = '';
  public croppedImage:any = '';
  public fileChangeEvent(event:any):void
  {
    this.imageChangedEvent = event;
    jQuery('#modalFoto').modal();
  }
  public imageCropped(event: ImageCroppedEvent)
  {
    this.croppedImage = event.base64;
  }
  public imageLoaded()
  {
    // show cropper
  }
  public cropperReady()
  {
  }
  public loadImageFailed()
  {
    // show message
  }

  public finalizarCorte()
  {
    let self = this;

    const id = self.curService.id+".png";
    self.ref = self._storage.ref(id);
    let newURL = self.croppedImage.replace('data:image/png;base64,', '');
    self.task = self.ref.putString(newURL, 'base64', {contentType:'image/png'})
    //self.task = self.ref.put(self.croppedImage);
    self.uploadProgress = self.task.percentageChanges();
    self.task.then(snapshot => {
      snapshot.ref.getDownloadURL().then(
        downloadURL => {
          self.imageChangedEvent = '';
          self.curService.data.pessoal.foto = {
            id:snapshot.metadata.name,
            url:downloadURL
          };
          self.curService.salvar();
          jQuery('#fotoUsuario').val('');
          jQuery('#modalFoto').modal('hide');
        }
      )
    });
  }

  public removerFoto()
  {
    let self = this;
    self._storage.ref(self.curService.data.pessoal.foto.id).delete();
    self.curService.data.pessoal.foto = null;
    self.curService.salvar();
  }

  public cancelarUpload()
  {
    let self = this;
    jQuery('#modalFoto').modal('hide');
    self.imageChangedEvent = '';
  }

}