import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../utils';
import { config } from '../config';
import * as firebase from 'firebase';

@Injectable()
export class PagamentoService
{
    public botaoFinalizarTexto:string = "Confirmo, efetuar pagamento!";
    public botaoFinalizarDisabled:boolean = false;

    public indiceSelecionado:number = 0;
    public lista:Array<any> = [];
    public constructor(
        private _http:HttpClient,
        private _database:AngularFireDatabase,
        public utils:Utils){}
    public obterCodigoCheckout(info, callback)
    {
        let self = this;
        let url = config.webservice+"curriculo/pagseguro/obter_codigo_checkout";
        self._http.post(url, info).toPromise()
            .then(data => { callback(data); })
            .catch(err => { console.log(err); });
    }
    public efetuar(info)
    {
        let self = this;

        self.botaoFinalizarTexto = "Carregando...";
        self.botaoFinalizarDisabled = true;

        let data = {
            id:null,
            info:info
        };
        self._database
            .list('compras')
            .push(info)
            .then(
                t => {
                    data.id = t.key;
                    self.obterCodigoCheckout(data, function(data)
                    {
                        self.botaoFinalizarTexto = "Confirmo, efetuar pagamento!";
                        self.botaoFinalizarDisabled = false;

                        if(data.status != 'success')
                        {
                            console.log('ERRO');
                        }
                        else
                        {
                            if(config.test)
                            {
                                location.href = "https://sandbox.pagseguro.uol.com.br/v2/checkout/payment.html?code="+data.autorizacao;
                            }
                            else
                            {
                                location.href = "https://pagseguro.uol.com.br/v2/checkout/payment.html?code="+data.autorizacao;
                            }
                            
                        }
                    });
                },
                e => {
                    self.botaoFinalizarTexto = "Confirmo, efetuar pagamento!";
                    self.botaoFinalizarDisabled = false;
                    console.log(e.message);
                });
    }
    public getPermissoesCV(idCurriculo, callback)
    {
        let self = this;
        let tsAtual = firebase.database.ServerValue.TIMESTAMP;

        let dias = 30;
        let tsDias = 60 * 60 * 24 * dias * 1000;
        let permissoes:any = {
            hasCompra:false,
            download:false,
            removerRodape:false
        };
        let data;
        let linha;
        self._database.list('compras').query
            .orderByChild('curriculo_id')
            .equalTo(idCurriculo)
            .on('value', snapshot => {
                data = snapshot.val();
                if(data == null || data == undefined)
                {
                    callback(permissoes);
                }
                else
                {
                    snapshot.forEach(row => {
                        linha = row.val();
                        
                        permissoes.hasCompra = true;
                        
                        //SE ESTIVER PAGA OU DISPONIVEL
                        if(linha.status == 3 || linha.status == 4)
                        {
                            if(linha.data_aprovacao + tsDias <= linha.data_limite_download)
                            {
                                for(let i = 0; i < linha.servicos.length; i++)
                                {
                                    if(linha.servicos[i].indice == 1)
                                    {
                                        permissoes.removerRodape = true;
                                    }
                                }
                            }
                        }
                    });
                    callback(permissoes);
                }
            });
    }
    public loadByCV(idCurriculo)
    {
        let self = this;
        self.lista = [];
        self.indiceSelecionado = 0;
        let data;
        let linha;
        self._database.list('compras').query
            .orderByChild('curriculo_id')
            .equalTo(idCurriculo)
            .on('value', snapshot => {
                data = snapshot.val();
                if(data != null && data != undefined)
                {
                    snapshot.forEach(row => {
                        linha = row.val();
                        linha.data = self.utils.getDataByTs(linha.data);
                        linha.data_aprovacao = self.utils.getDataByTs(linha.data_aprovacao);
                        linha.data_limite_download = self.utils.getDataByTs(linha.data_limite_download);
                        self.lista.push(linha);
                    });
                }
                console.log(self.lista);
            });
    }
}