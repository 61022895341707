import { Component, OnInit, Input } from '@angular/core';
import { CurriculoService } from '../../../../providers/services/curriculo-service';

declare var jQuery;

@Component({
  selector: 'app-view-curriculo-preenchimento-conteudo-tema1',
  templateUrl: './curriculo-preenchimento-conteudo-tema1.component.html',
  styleUrls: ['./curriculo-preenchimento-conteudo-tema1.component.css']
})
export class CurriculoPreenchimentoConteudoTema1Component implements OnInit
{
  public constructor(public curService:CurriculoService){}
  public ngOnInit(){}
}