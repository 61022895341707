import { Component, OnInit } from '@angular/core';
import { CurriculoService } from '../../../../providers/services/curriculo-service';

@Component({
  selector: 'app-view-curriculo-preenchimento-destaque',
  templateUrl: './curriculo-preenchimento-destaque.component.html',
  styleUrls: ['./curriculo-preenchimento-destaque.component.css']
})
export class CurriculoPreenchimentoDestaqueComponent implements OnInit
{
  public constructor(public curService:CurriculoService)
  {
  }
  public ngOnInit()
  {
  }
}