import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurriculoService } from '../../providers/services/curriculo-service';
import { LogService } from '../../providers/services/log-service';
import { PagamentoService } from '../../providers/services/pagamento-service';
import { PDFService } from '../../providers/services/pdf-service';

declare var jQuery;

@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit
{
    public force:boolean = false;
    public id:string;
    private _sub:any;
    public constructor(private _activatedRoute:ActivatedRoute, public curService:CurriculoService, public logService:LogService, public pagService:PagamentoService, public pdfService:PDFService)
    {
        this.curService.limparDados();
    }
    public ngOnInit()
    {
        let self = this;
        jQuery('#modalCarregando').modal();
        self._sub = self._activatedRoute.params.subscribe(params => {
            self.id = params['id'];
            let force = params['force'];
            if(force === true || force === "true"){ self.force = force; }

            jQuery('#modalCarregando').modal('hide');

            if(!self.force)
            {
                self.pagService.getPermissoesCV(self.id, function(data)
                {
                    self.curService.removerRodape = data.removerRodape;
                });
                self.logService.gravar(self.id, 'acesso');
            }
            self.curService.verificacaoInicialView(self.id, self.force);
        });
    }
    public gerarPDF()
    {
        let self = this;
        if(!self.force){ self.logService.gravar(self.id, 'download'); }
        self.pdfService.gerar(self.curService.removerRodape);
    }
}