import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadastroComponent } from './cadastro.component';

import { AngularFireAuth } from 'angularfire2/auth';

@NgModule({
  imports:[CommonModule,FormsModule],
  declarations:[CadastroComponent],
  exports:[CadastroComponent],
  providers:[AngularFireAuth]
})
export class CadastroModule {}