import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from '../../providers/utils';

import * as firebase from 'firebase';

declare var jQuery;

@Component({
  selector: 'app-esquecisenha',
  templateUrl: './esquecisenha.component.html',
  styleUrls: ['./esquecisenha.component.css']
})
export class EsqueciSenhaComponent implements OnInit
{
    public email:string = null;
    public msgErro:string = null;
    public msgSucesso:string = null;
    public constructor(private _router:Router, private _utils:Utils)
    {
    }
    public ngOnInit()
    {
        let self = this;
        jQuery("input").on('keydown', function(e)
        {
          if(e.which == 13)
          {
            e.preventDefault();
            self.iniciarRecuperacao();
          }
        });
   
    }
    public iniciarRecuperacao()
    {
        let self = this;
        
        self.msgErro = null;
        if(self.email == "" || self.email == null)
        {
            self.msgErro = "Você não digitou o e-mail";
        }
        else if(!self._utils.isEmail(self.email))
        {
            self.msgErro = "E-mail inválido";
        }
        else
        {
            firebase.auth().sendPasswordResetEmail(self.email)
                .then(ok => {
                    console.log(ok);

                    self.msgSucesso = "Acesse sua caixa de e-mails (<b style='font-weight:bold;'>"+self.email+"</b>) e clique no link da mensagem que acabamos de te enviar para re-definir sua senha.";

                    self.email = null;
                    self.msgErro = null;
                })
                .catch(err => {
                    console.log(err);
                    let patternNotFound = /user\-not\-found/;
                    if(patternNotFound.test(err.code))
                    {
                        self.msgErro = "E-mail não encontrado";
                    }
                });
        }
    }
}