import { Component, OnInit } from '@angular/core';
import { CurriculoService } from '../../../../providers/services/curriculo-service';
import { Utils } from '../../../../providers/utils';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit
{
    public limite:number = 20;
    public inicio:number = 0;
    public quantidade:number;
    public listaGeral:Array<any> = [];
    public lista:Array<any> = [];
    public listaCortada:Array<any> = [];
    public idsListaAtual:Array<any> = [];
    public filtro = {
        foto:null,
        visibilidade:null,
        cor:null,
        tema:null,
        busca:null,
    };
    public opcoesVisibilidade:Array<any> = [
        {valor:null, texto:'Todos'},
        {valor:true, texto:'Públicos'},
        {valor:false, texto:'Privados'},
    ];
    public opcoesCor:Array<any> = [
        {valor:null, texto:'Todas'},
        {valor:1, texto:'Azul'},
        {valor:2, texto:'Azul escuro'},
        {valor:3, texto:'Laranja'},
        {valor:4, texto:'Marrom'},
        {valor:5, texto:'Preto'},
        {valor:6, texto:'Rosa'},
        {valor:7, texto:'Roxo'},
        {valor:8, texto:'Verde'},
    ];
    public opcoesTema:Array<any> = [
        {valor:null, texto:'Todos'},
        {valor:2, texto:'Uma coluna'},
        {valor:1, texto:'Duas colunas'},
    ];
    public opcoesFoto:Array<any> = [
        {valor:null, texto:'Todos'},
        {valor:true, texto:'Com foto'},
        {valor:false, texto:'Sem foto'},
    ];

    public constructor(public curService:CurriculoService, public utils:Utils){}
    public ngOnInit(){ this.carregarListaGeral() }
    public carregarListaGeral()
    {
        let self = this;
        self.curService.getByFiltro(function(ret)
        {
            self.listaGeral = Object.assign([], ret);
            self.lista = Object.assign([], ret);
            self.listaCortada = Object.assign([], ret.splice(0, self.limite));
        });
    }

    public tmBusca:any = null;
    public preFiltrar()
    {
        let self = this;
        if(self.tmBusca != null && self.tmBusca != undefined)
        {
            clearTimeout(self.tmBusca);
        }

        self.tmBusca = setTimeout(function(){
            self.filtrar();
        }, 500);
    }

    public filtrar()
    {
        let self = this;
        
        let indicesRemover:Array<number> = [];
        /********************************************
         * FILTRAR POR FOTO
         ********************************************/
        let foto = false;
        if(self.filtro.foto != null && self.filtro.foto != 'null')
        {
            for(let i = 0; i < self.listaGeral.length; i++)
            {
                foto = false;
                if(self.listaGeral[i].data.pessoal.configuracoes.mostrar_foto)
                {
                    if(self.listaGeral[i].data.pessoal.foto != null && self.listaGeral[i].data.pessoal.foto != undefined)
                    {
                        foto = true;
                    }
                }
                if(foto.toString() != self.filtro.foto.toString())
                {
                    if(!self.utils.inArray(indicesRemover, i)){ indicesRemover.push(i); }
                }
            }
        }

        /********************************************
         * FILTRAR POR VISIBILIDADE
         ********************************************/
        if(self.filtro.visibilidade != null && self.filtro.visibilidade != 'null')
        {
            for(let i = 0; i < self.listaGeral.length; i++)
            {
                if(self.listaGeral[i].data.publico.toString() != self.filtro.visibilidade.toString())
                {
                    if(!self.utils.inArray(indicesRemover, i)){ indicesRemover.push(i); }
                }
            }
        }

        /********************************************
         * FILTRAR POR COR
         ********************************************/
        if(self.filtro.cor != null && self.filtro.cor != 'null')
        {
            for(let i = 0; i < self.listaGeral.length; i++)
            {
                if(self.listaGeral[i].data.cor.toString() != self.filtro.cor.toString())
                {
                    if(!self.utils.inArray(indicesRemover, i)){ indicesRemover.push(i); }
                }
            }
        }

        /********************************************
         * FILTRAR POR TEMA
         ********************************************/
        if(self.filtro.tema != null && self.filtro.tema != 'null')
        {
            for(let i = 0; i < self.listaGeral.length; i++)
            {
                if(self.listaGeral[i].data.tema_numero.toString() != self.filtro.tema.toString())
                {
                    if(!self.utils.inArray(indicesRemover, i)){ indicesRemover.push(i); }
                }
            }
        }

        /********************************************
         * FILTRAR PELA BUSCA
         ********************************************/
        let pattern = null;
        if(self.filtro.busca != null && self.filtro.busca != 'null' && self.filtro.busca != '')
        {
            pattern = new RegExp(self.filtro.busca, "gi");
            for(let i = 0; i < self.listaGeral.length; i++)
            {
                if(!pattern.test(JSON.stringify(self.listaGeral[i].data)))
                {
                    if(!self.utils.inArray(indicesRemover, i)){ indicesRemover.push(i); }
                }
            }

        }

        self.lista = [];
        self.listaCortada = [];
        for(let i = 0; i < self.listaGeral.length; i++)
        {
            if(!self.utils.inArray(indicesRemover, i))
            {
                self.lista.push(self.listaGeral[i]);
                if(self.listaCortada.length < self.limite)
                {
                    self.listaCortada.push(self.listaGeral[i]);
                }
            }
        }
    }

    public mais()
    {
        let self = this;
        let limite = self.listaCortada.length + self.limite
        for(let i = self.listaCortada.length; i < self.lista.length; i++)
        {
            if(i < limite)
            {
                self.listaCortada.push(self.lista[i]);
            }
        }
    }

}