import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../../providers/services/menu-service';

declare var jQuery;

@Component({
    selector: 'app-precos',
    templateUrl: './precos.component.html',
    styleUrls: ['./precos.component.css']
})
export class PrecosComponent implements OnInit
{
    public constructor(public menuService:MenuService)
    {
        this.menuService.selecionado = 2;
    }
    public ngOnInit()
    {
        jQuery('#modalCarregando').modal('hide');
    }
}