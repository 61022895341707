import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewComponent } from './view.component';
import { CurriculoPreenchimentoModule } from './curriculo-preenchimento/curriculo-preenchimento.module';

@NgModule({
  imports:[CommonModule,FormsModule,CurriculoPreenchimentoModule],
  declarations:[ViewComponent],
  exports:[ViewComponent]
})
export class ViewModule { }