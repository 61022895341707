import { Component, OnInit } from '@angular/core';
import { CurriculoService } from '../../../../../../../providers/services/curriculo-service';
import { FormBuilder } from "@angular/forms";
import { AngularFireStorage } from 'angularfire2/storage';

@Component({
  selector: 'app-destaque-mini',
  templateUrl: './destaquemini.component.html',
  styleUrls: ['./destaquemini.component.css']
})
export class DestaqueMiniComponent implements OnInit
{
  public constructor(public curService:CurriculoService, private _fb:FormBuilder, private _storage:AngularFireStorage)
  {
  }
  public ngOnInit()
  {
  }

}