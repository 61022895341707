import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from '../../providers/utils';
import * as firebase from 'firebase';

declare var jQuery;

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css']
})
export class CadastroComponent implements OnInit
{
    public botaoCadastroTexto:string = "Cadastrar";
    public botaoCadastroDisabled:boolean = false;

    public nome:string = null;
    public email:string = null;
    public confirmacaoEmail:string = null;
    public senha:string = null;
    public confirmacao:string = null;
    public aceitouTermosPolitica:boolean = false;
    public msgErro = null;
    public constructor(private _router:Router, private _utils:Utils){}
    public ngOnInit(){}
    public setAceitouTermosPolitica()
    {
        this.aceitouTermosPolitica = !this.aceitouTermosPolitica;
    }
    public termos(event)
    {
        event.stopPropagation();
        event.preventDefault();
        jQuery('#modalTermos').modal();
    }
    public politica(event)
    {
        event.stopPropagation();
        event.preventDefault();
        jQuery('#modalPolitica').modal();
    }
    public iniciarCadastro()
    {
        let self = this;
        
        self.msgErro = null;
        if(self.nome == "" || self.nome == null)
        {
            self.msgErro = "Você não digitou o nome completo";
        }
        else if(self.email == "" || self.email == null)
        {
            self.msgErro = "Você não digitou o e-mail";
        }
        else if(!self._utils.isEmail(self.email))
        {
            self.msgErro = "E-mail inválido";
        }
        else if(self.confirmacaoEmail == null || self.confirmacaoEmail == "")
        {
            self.msgErro = "Digite o e-mail de confirmação";
        }
        else if(self.email != self.confirmacaoEmail)
        {
            self.msgErro = "O e-mail e a confirmação não conferem";
        }
        else if(self.senha == null || self.senha == "")
        {
            self.msgErro = "Digite a senha desejada";
        }
        else if(self.senha.length < 6)
        {
            self.msgErro = "A senha deve conter no mínimo 6 caracteres";
        }
        else if(self.confirmacao == null || self.confirmacao == "")
        {
            self.msgErro = "Digite a confirmação de senha";
        }
        else if(self.senha != self.confirmacao)
        {
            self.msgErro = "A senha e a confirmação não conferem";
        }
        else if(!self.aceitouTermosPolitica)
        {
            self.msgErro = "Você não aceitou os Termos de uso e a Política de privacidade";
        }
        else
        {
            self.botaoCadastroTexto = "<i class='fa fa-spinner fa-pulse fa-fw'></i> &nbsp; Cadastrando...";
            self.botaoCadastroDisabled = true;
        
            firebase.auth().createUserWithEmailAndPassword(self.email, self.senha)
                .then(ok => {
                    let user = firebase.auth().currentUser;
                    user.updateProfile({
                        displayName:self.nome
                    }).then(function()
                    {
                        self.zerarFormERedirecionar();
                    }).catch(function(error)
                    {
                        console.log(error);
                        self.zerarFormERedirecionar();
                    });
                })
                .catch(err => {
                    let patternInUse = /already\in\-use/;
                    if(!patternInUse.test(err.code))
                    {
                        self.msgErro = "E-mail já cadastrado";
                    }
                    else
                    {
                        console.log(err);
                    }
                    self.botaoCadastroTexto = "Cadastrar";
                    self.botaoCadastroDisabled = false;
                });
        }
    }

    public zerarFormERedirecionar()
    {
        let self = this;
        self.botaoCadastroTexto = "Cadastrar";
        self.botaoCadastroDisabled = false;
        self._router.navigate(["/inside/curriculo"]);
    }
}