import { Component, OnInit } from '@angular/core';
import { CurriculoService } from '../../../providers/services/curriculo-service';

@Component({
  selector: 'app-view-curriculo-preenchimento',
  templateUrl: './curriculo-preenchimento.component.html',
  styleUrls: ['./curriculo-preenchimento.component.css']
})
export class CurriculoPreenchimentoComponent implements OnInit
{
  public constructor(public curService:CurriculoService){}
  public ngOnInit()
  {
  }
}