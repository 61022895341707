import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin/admin.component';
import { MainComponent } from './admin/main/main.component';
import { DashboardComponent } from './admin/main/dashboard/dashboard.component';
import { RevisoesComponent } from './admin/main/revisoes/revisoes.component';

import { SiteComponent } from './site/site.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { InsideComponent } from './inside/inside.component';
import { CurriculoComponent } from './inside/curriculo/curriculo.component';
import { PrecosComponent } from './inside/precos/precos.component';
import { SobreComponent } from './inside/sobre/sobre.component';
import { ContatoComponent } from './inside/contato/contato.component';
import { EdicaoComponent } from './inside/curriculo/edicao/edicao.component';
import { CadastroComponent } from './cadastro/cadastro.component';
import { EsqueciSenhaComponent } from './esquecisenha/esquecisenha.component';
import { ViewComponent } from './view/view.component';

const routes: Routes = [
  {path:'', component:SiteComponent},
  {path:'login', component:LoginFormComponent},
  {path:'admin', component:AdminComponent},
  {path:'admin/main', component:MainComponent, children:[
    {path:'dashboard', component:DashboardComponent},
    {path:'revisoes', component:RevisoesComponent},
  ]},
  {path:'inside', component:InsideComponent, children:[
    {path:'curriculo', component:CurriculoComponent},
    {path:'curriculo/edicao/:id', component:EdicaoComponent},
    {path:'precos', component:PrecosComponent},
    {path:'sobre', component:SobreComponent},
    {path:'contato', component:ContatoComponent},
  ]},
  {path:'cadastro', component:CadastroComponent},
  {path:'esquecisenha', component:EsqueciSenhaComponent},
  {path:'view/:id', component:ViewComponent},
  {path:'view/:id/:force', component:ViewComponent},
];

@NgModule({
  imports:[RouterModule.forRoot(routes, {
    useHash:true,
    //enableTracing:true
  })],
  exports:[RouterModule]
})
export class AppRoutingModule{}