import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { CurriculoService } from '../../../../providers/services/curriculo-service';
import { ServicoService } from '../../../../providers/services/servico-service';
import { PagamentoService } from '../../../../providers/services/pagamento-service';
import { MenuService } from '../../../../providers/services/menu-service';

declare var jQuery;

@Component({
  selector: 'app-edicao',
  templateUrl: './edicao.component.html',
  styleUrls: ['./edicao.component.css']
})
export class EdicaoComponent implements OnInit
{
  private _sub:any;
  public id:string;
  public constructor(private _router:Router, private _activatedRoute:ActivatedRoute, public curService:CurriculoService, public serService:ServicoService, public pagService:PagamentoService, public menuService:MenuService)
  {
    this.menuService.selecionado = 1;
  }
  public ngOnInit()
  {
    let self = this;
    self.curService.limparDados();
    self._sub = self._activatedRoute.params.subscribe(params => {
      self.id = params['id'];
      self.pagService.getPermissoesCV(self.id, function(data)
      {
          self.curService.hasCompra = data.hasCompra
          self.curService.removerRodape = data.removerRodape;
          if(self.curService.removerRodape)
          {
            self.curService.permitirAlterarNome = false;
          }
      });

      jQuery('#modalCarregando').modal('hide');
      self.curService.verificacaoInicialEdicao(self.id);

      let obsTemp:string;

      /********************************************************************************
       * AJUSTO AS MODAIS CARREGADAS DENTRO DE OUTRAS
       ********************************************************************************/
      jQuery(document).on('show.bs.modal', '.modal', function(event)
      {
          let zIndex = 1040 + (10 * jQuery('.modal:visible').length);
          jQuery(this).css('z-index', zIndex);
          setTimeout(function()
          {
              jQuery('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
          }, 0);
      });

      /********************************************************************************
       * AO ABRI MODAL, CARREGO OS POPOVERS
       ********************************************************************************/
      jQuery('#modalUpgrade').on('shown.bs.modal', function(event)
      {
        for(let i = 0; i < self.serService.opcoes.length; i++)
        {
          obsTemp = "<ul>";
          for(let j = 0; j < self.serService.opcoes[i].observacoes.length; j++)
          {
            obsTemp+= "<li>"+self.serService.opcoes[i].observacoes[j]+"</li>";
          }
          obsTemp+= "</ul>";

          jQuery('#'+self.serService.opcoes[i].idPopOver).popover({
            trigger:'hover',
            html:true,
            title:self.serService.opcoes[i].observacoes.length == 1 ? "Observação" : "Observações",
            content:obsTemp
          });
        }
      });
    });
  }

  public menuMobileOculto:boolean = true;
  public menuMobileTextoBotao:string = "Opções";
  public mostrarOculparMenu()
  {
    let self = this;
    self.menuMobileOculto = !self.menuMobileOculto;
    self.menuMobileTextoBotao = self.menuMobileOculto ? "Opções" : "Ocultar opções";
  }
  public selecionarCor(indice)
  {
    let self = this;
    self.curService.data.cor = indice;
    self.curService.salvar();
    jQuery('#modalTrocarCorDestaque').modal('hide');
  }
  public setTema(tema)
  {
    let self = this;
    self.curService.data.tema_numero = tema;
    self.curService.salvar();
    self.curService.ajustarSecoesInseridas();
    self.curService.ajustarMidiasInseridas();
    self.curService.ajustarTextareas();

    jQuery('#modalTrocarTema').modal('hide');
  }

  public removerFim()
  {
    let self = this;
    jQuery('#modalRemover').modal('hide');
    self.curService.removerCV(function(data)
    {
      console.log(data);
      self._router.navigate(["/inside/curriculo"]);
    });
  }

}