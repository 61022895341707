import { Component, Input } from '@angular/core';
import { CurriculoService } from '../../../../../../../providers/services/curriculo-service';

declare var jQuery;

@Component({
  selector: 'app-coluna',
  templateUrl: './coluna.component.html',
  styleUrls: ['./coluna.component.css']
})
export class ColunaComponent
{
  @Input() coluna:string;
  public idColuna:string;
  public constructor(public curService:CurriculoService)
  {
    let self = this;
  }
  public novaSecao(coluna)
  {
    let self = this;

    jQuery('#modalNovaSecao').modal();
    self.curService.marcarPrimeiraOpcaoDisponivel();
    self.curService.coluna = coluna == 'A' ? 1 : 2;

  }
}