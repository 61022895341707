import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { CurriculoPreenchimentoDestaqueComponent } from './curriculo-preenchimento-destaque/curriculo-preenchimento-destaque.component';
import { CurriculoPreenchimentoComponent } from './curriculo-preenchimento.component';

import { CurriculoPreenchimentoConteudoTema1Component } from './curriculo-preenchimento-conteudo-tema1/curriculo-preenchimento-conteudo-tema1.component';
import { CurriculoPreenchimentoConteudoTema2Component } from './curriculo-preenchimento-conteudo-tema2/curriculo-preenchimento-conteudo-tema2.component';
import { CurriculoPreenchimentoConteudoTema1ColunaComponent } from './curriculo-preenchimento-conteudo-tema1/curriculo-preenchimento-conteudo-tema1-coluna/curriculo-preenchimento-conteudo-tema1-coluna.component';

@NgModule({
  imports:[CommonModule,FormsModule,ReactiveFormsModule],
  exports:[CurriculoPreenchimentoComponent,CurriculoPreenchimentoDestaqueComponent],
  declarations:[CurriculoPreenchimentoDestaqueComponent,CurriculoPreenchimentoComponent, CurriculoPreenchimentoConteudoTema1Component, CurriculoPreenchimentoConteudoTema2Component, CurriculoPreenchimentoConteudoTema1ColunaComponent],
})
export class CurriculoPreenchimentoModule{}