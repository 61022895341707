import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { DestaqueComponent } from './destaque/destaque.component';
import { DestaqueMiniComponent } from './destaque/mini/destaquemini.component';
import { PreenchimentoComponent } from './preenchimento.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import { Tema1Component } from './conteudo/tema1/tema1.component';
import { Tema1MiniComponent } from './conteudo/tema1/mini/tema1mini.component';
import { Tema2Component } from './conteudo/tema2/tema2.component';
import { Tema2MiniComponent } from './conteudo/tema2/mini/tema2mini.component';

import { ColunaComponent } from './conteudo/coluna/coluna.component';
import { ColunaMiniComponent } from './conteudo/coluna/mini/colunamini.component';

@NgModule({
  imports:[CommonModule,FormsModule,ReactiveFormsModule,ImageCropperModule],
  exports:[PreenchimentoComponent,DestaqueComponent,DestaqueMiniComponent],
  declarations:[DestaqueComponent,DestaqueMiniComponent,PreenchimentoComponent, Tema1Component, Tema1MiniComponent, Tema2Component, Tema2MiniComponent, ColunaComponent, ColunaMiniComponent],
})
export class PreenchimentoModule { }
