import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteComponent } from './site.component';

import { AngularFireAuth } from 'angularfire2/auth';

@NgModule({
  imports:[CommonModule,FormsModule],
  declarations:[SiteComponent],
  exports:[SiteComponent],
  providers:[AngularFireAuth]
})
export class SiteModule { }