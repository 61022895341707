import { Component, OnInit } from '@angular/core';
import { CurriculoService } from '../../../../../providers/services/curriculo-service';

@Component({
  selector: 'app-preenchimento',
  templateUrl: './preenchimento.component.html',
  styleUrls: ['./preenchimento.component.css']
})
export class PreenchimentoComponent implements OnInit
{
  public constructor(public curService:CurriculoService){}
  public ngOnInit()
  {
    
  }
}