import { Component, OnInit, Input } from '@angular/core';
import { CurriculoService } from '../../../../../../../providers/services/curriculo-service';

declare var jQuery;

@Component({
  selector: 'app-tema1',
  templateUrl: './tema1.component.html',
  styleUrls: ['./tema1.component.css']
})
export class Tema1Component implements OnInit
{
  public constructor(public curService:CurriculoService){}
  public ngOnInit()
  {
  }
  public novaSecao(coluna)
  {
    let self = this;

    jQuery('#modalNovaSecao').modal();
    self.curService.marcarPrimeiraOpcaoDisponivel();
    self.curService.coluna = coluna;
  }
}