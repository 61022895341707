import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from '../../providers/utils';
import { AdminService } from '../../providers/services/admin-service';
import * as firebase from 'firebase/app';

declare var jQuery;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit
{
  public usuarioLogado:any = null;
  public msgErro:string = null;
  public email:string;
  public senha:string;
  public constructor(private _router:Router, private _utils:Utils, public admService:AdminService)
  {
    //this.email = "denecir@gmail.com";
    //this.senha = "123321";
  }
  public ngOnInit()
  {
    let self = this;

    //SE ESTIVER LOGADO, LOGOUT
    firebase.auth().onAuthStateChanged(function(usuario)
    {
      if(usuario)
      {
        self.usuarioLogado = usuario;
      }
    });

    //PERMITE LOGIN COM ENTER
    jQuery("input").on('keydown', function(e)
    {
      if(e.which == 13)
      {
        e.preventDefault();
        self.login();
      }
    });
  }
  public login()
  {
    let self = this;

    if(self.email == null || self.email == undefined || self.email == "")
    {
      self.msgErro = 'Digite o e-mail';
    }
    else if(!self._utils.isEmail(self.email))
    {
      self.msgErro = 'E-mail inválido';
    }
    else if(self.senha == null || self.senha == undefined || self.senha == "")
    {
      self.msgErro = 'Digite a senha';
    }
    else
    {
      //SE ESTIVER LOGADO, SAI ANTES DE ENTRAR
      if(self.usuarioLogado == null)
      {
        self.finalizarLogin();
      }
      else
      {
        self.admService.idUsuario = null;
        firebase.auth().signOut()
          .then(ok => {
            self.finalizarLogin();
          })
          .catch(err => {
            console.error(err);
          });
      }
    }
  }
  public finalizarLogin()
  {
    let self = this;
    firebase.auth()
      .signInWithEmailAndPassword(self.email, self.senha)
      .then(ok => {
        self._router.navigate(["/admin/main/dashboard"]);
        self.email = null;
        self.senha = null;
      })
      .catch(err => {
        self.msgErro = 'E-mail ou senha inválido';
        self.senha = null;
        console.error(err.message);
      });
  }
}