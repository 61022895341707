import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase/app';

declare var jQuery;

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.css']
})
export class SiteComponent implements OnInit
{
    public constructor(private _router:Router){}
    public ngOnInit()
    {
      let self = this;

      jQuery('body').attr('data-spy', 'scroll');
      jQuery('body').attr('data-target', '#menu-principal');
      jQuery('body').attr('data-offset', '0');
    }
}