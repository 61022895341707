import { BrowserModule } from '@angular/platform-browser';
import { NgModule,LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { Utils } from '../providers/utils';
import { CurriculoService } from '../providers/services/curriculo-service';
import { PDFService } from '../providers/services/pdf-service';
import { ServicoService } from '../providers/services/servico-service';
import { PagamentoService } from '../providers/services/pagamento-service';
import { LogService } from '../providers/services/log-service';
import { MenuService } from '../providers/services/menu-service';
import { AdminService } from '../providers/services/admin-service';

import { FirebaseConfig } from './../environments/firebase.config';
import { AngularFireModule } from 'angularfire2/index';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireStorageModule } from 'angularfire2/storage';

import { AdminModule } from './admin/admin.module';
import { InsideModule } from './inside/inside.module';
import { LoginModule } from './login/login.module';
import { SiteModule } from './site/site.module';
import { CadastroModule } from './cadastro/cadastro.module';
import { EsqueciSenhaModule } from './esquecisenha/esquecisenha.module';
import { ViewModule } from './view/view.module';

import { HttpClientModule } from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

@NgModule({
  declarations:[
    AppComponent
  ],
  imports:[
    SiteModule,
    LoginModule,
    CadastroModule,
    EsqueciSenhaModule,
    ViewModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    AdminModule,
    InsideModule,
    AngularFireModule.initializeApp(FirebaseConfig),
    AngularFireStorageModule,
  ],
  providers: [
    PDFService,
    ServicoService,
    PagamentoService,
    LogService,
    MenuService,
    CurriculoService,
    AdminService,
    Utils,
    AngularFireDatabase,
    {
      provide:LOCALE_ID,
      useValue:'pt-BR'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }